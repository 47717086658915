import React from "react"
import _ from "lodash"
import { graphql, StaticQuery } from "gatsby"
import { Link } from "gatsby"

const Tags = () => {
  return (
    <StaticQuery
      query={tagsQuery}
      render={data => {
        const tags = data.allMarkdownRemark.distinct
        return (
          <div className="tag-container">
            {tags.map(tag => {
              return (
                <Link
                  key={tag}
                  style={{ textDecoration: "none" }}
                  to={`/tags/${_.kebabCase(tag)}`}
                >
                  <div className="tag-item">#{tag}</div>
                </Link>
              )
            })}
          </div>
        )
      }}
    />
  )
}

const tagsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      distinct(field: frontmatter___tags)
    }
  }
`

export default Tags
